import { INGESTION_RESOURCE, IMPORT_MAIL_RESOURCE } from '@/services/keys';
import { api } from '.';

export const getResources = () => ({
  [INGESTION_RESOURCE]: undefined,
  [IMPORT_MAIL_RESOURCE]: undefined,
});

/**
 *
 * @param {platformId, email_id} data
 * @return {Promise.<data:object>}
 */
export function reloadPlatformMail(data) {
  const partialUrl = api.createUrl(getResources());

  return api.create(partialUrl, data);
}
