<template>
  <section id="platform-details" class="w-full">
    <section-header :is-loading="isLoading">
      <template v-if="!isLoading" #title>
        <breadcrumb class="flex flex-1" />
      </template>
    </section-header>
    <div class="p-6 text-left bg-white rounded-lg shadow-md lg:p-12">
      <sun-form @submit="save">
        <card-form class="platform-card">
          <template #title>Basic Info</template>
          <template #form>
            <form-row>
              <template #left>
                <sun-label-group text="Name">
                  <asterix-input
                    id="platform-name"
                    v-model="platform.name"
                    label="Name"
                    :maxlength="50"
                    :minlength="3"
                    name="platform-name"
                    placeholder="Platform name"
                    required="required"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Provider">
                  <asterix-input
                    v-model="platform.provider.name"
                    disabled
                    label="Provider"
                    name="provider"
                    placeholder="Platform Provider"
                  />
                </sun-label-group>
              </template>
            </form-row>
            <form-row>
              <save-button :loading="loading" :disabled="isLoading" />
            </form-row>
          </template>
        </card-form>
      </sun-form>
      <card-form class="history-card mt-8">
        <template #title> Fee History</template>
        <template #form>
          <sun-data-table :headers="historyHeaders" :content="platform.platformFees" hoverable :loading="isLoading">
            <template #[`col.date`]="{ item }">
              <sun-data-table-cell :class="{ 'font-bold': !item.endDate }">
                <div class="flex flex-row justify-center">
                  <date-tooltip class="border-none" :date="item.startDate" />
                  <template v-if="item.endDate">
                    <span class="mx-3">-</span>
                    <date-tooltip class="border-none" :date="item.endDate" />
                  </template>
                  <template v-else>
                    <span class="mx-3">-</span>
                    <span>{{ notEndDate }}</span>
                  </template>
                </div>
              </sun-data-table-cell>
            </template>

            <template #[`col.openFee`]="{ item, columnClass }">
              <sun-data-table-cell class="text-center" :class="[{ 'font-bold': !item.endDate }, columnClass]">
                <span>{{ item.openFee }}%</span>
              </sun-data-table-cell>
            </template>

            <template #[`col.dealFee`]="{ item, columnClass }">
              <sun-data-table-cell class="text-center" :class="[{ 'font-bold': !item.endDate }, columnClass]">
                <span>{{ item.dealsFee }}%</span>
              </sun-data-table-cell>
            </template>

            <template #[`col.actions`]="{ item }">
              <sun-data-table-cell>
                <table-action-menu v-if="!item.endDate" :actions="historyActions" @click="onEditPlatformFee()" />
              </sun-data-table-cell>
            </template>
            <template #empty>
              <asterix-no-data class="bg-white text-center" />
            </template>
          </sun-data-table>
        </template>
      </card-form>
      <card-form v-if="platform.emailIngestions.length">
        <template #title>Email ingestions</template>
        <template #form>
          <sun-data-table
            :headers="emailIngestionHeaders"
            :content="platform.emailIngestions"
            hoverable
            :loading="isLoading"
          >
            <template #[`col.ingestionDate`]="{ item }">
              <sun-data-table-cell class="text-center font-bold">
                <date-tooltip class="border-none" :date="item.ingestionDate" />
              </sun-data-table-cell>
            </template>
            <template #[`col.status`]="{ item }">
              <sun-data-table-cell>
                <status-pill :status="item.status" />
              </sun-data-table-cell>
            </template>
            <template #[`col.subject`]="{ item, columnClass }">
              <sun-data-table-cell class="font-bold" :class="columnClass">
                <span>{{ item.subject }}</span>
              </sun-data-table-cell>
            </template>
            <template #[`col.datesInEmail`]="{ item, columnClass }">
              <sun-data-table-cell class="font-bold" :class="columnClass">
                <span v-for="(date, index) in item.ingestionDates" :key="index" class="inline-block">
                  <date-tooltip class="border-none mr-2" :date="date" />
                </span>
              </sun-data-table-cell>
            </template>
            <template #[`col.actions`]="{ item }">
              <sun-data-table-cell>
                <table-action-menu :actions="emailActions" @click="onReloadEmail(item)" />
              </sun-data-table-cell>
            </template>
            <template #empty>
              <asterix-no-data class="bg-white text-center" />
            </template>
          </sun-data-table>
        </template>
      </card-form>
    </div>
    <edit-fee-modal
      v-if="showEditPlatformFeeModal"
      :fee="platform.platformFees[0]"
      @save="onSavePlatformFee"
      @cancel="showEditPlatformFeeModal = false"
    />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SET_LOAD, IS_LOADING } from '@/store/modules/load/keys';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import Platform from '@/entities/dashboardSSP/Platform';
import SectionHeader from '@/components/atoms/SectionHeader';
import { getPlatformById, updatePlatform } from '@/services/modules/dashboardSSP/platform';
import { Toast } from '@/model/shared/Toast';
import {
  SET_LOADING_TOAST,
  SET_STATE_ERROR_TOAST,
  SET_STATE_LOADING_TOAST,
  SET_STATE_SUCCESS_TOAST,
} from '@/store/modules/loadingToast/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import CardForm from '@/components/atoms/CardForm';
import metaInfo from '@/mixins/common/metaInfo';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import SaveButton from '@/components/atoms/SaveButton';
import FormRow from '@/components/atoms/FormRow/FormRow';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import DateTooltip from '@/components/atoms/DateTooltip/DateTooltip';
import Column from '@/model/shared/Column';
import { list as platformList } from '@/router/private/modules/dashboardSSP/platforms/list.js';
import PlatformFee from '@/entities/dashboardSSP/PlatformFee';
import AsterixInput from '@/components/atoms/AsterixInput';
import { reloadPlatformMail } from '@/services/modules/dashboardSSP/platform/reloadPlatformMail';

export default {
  name: 'PlatformEdit',
  components: {
    Breadcrumb,
    FormRow,
    CardForm,
    SectionHeader,
    DateTooltip,
    AsterixInput,
    SaveButton,
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    EditFeeModal: () => import('@/views/private/modules/dashboardSSP/platform/EditFeeModal'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
  },
  mixins: [activeClientMixin, metaInfo],
  props: {
    platformId: { type: String, default: () => null, require: true },
  },
  data: () => ({
    platform: new Platform(),
    loading: false,

    platformProviders: [],
    historyHeaders: [
      new Column('DATE', 'date'),
      new Column('OPEN FEE', 'openFee').toRight(),
      new Column('DEAL FEE', 'dealFee').toRight(),
      new Column('ACTIONS', 'actions').setClass('w-4'),
    ],
    emailIngestionHeaders: [
      new Column('INGESTION DATE', 'ingestionDate'),
      new Column('STATUS', 'status'),
      new Column('SUBJECT', 'subject'),
      new Column('DATES IN EMAIL', 'datesInEmail'),
      new Column('ACTIONS', 'actions').setClass('w-4'),
    ],
    showEditPlatformFeeModal: false,
    historyActions: [{ name: 'Edit' }],
    notEndDate: 'NO END DATE',
    emailActions: [{ name: 'Reload' }],
  }),
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      isLoading: IS_LOADING,
    }),
    platformProvider: {
      get() {
        return this.platform.platformProvider?.id ? this.platform.platformProvider : null;
      },
      set(val) {
        this.platform.platformProvider = val;
      },
    },
    disabledApiUserConnection() {
      return !this.platform.platformProvider?.id || this.isLoading;
    },
    lastFeeOrNew() {
      return this.platform.platformFees.length ? this.platform.platformFees[0] : new PlatformFee();
    },
    lastOpenProgrammatic() {
      return this.lastFeeOrNew.openProgrammatic;
    },
    lastProgramaticDirect() {
      return this.lastFeeOrNew.programmaticDirect;
    },
    lastSspProgrammatic() {
      return this.lastFeeOrNew.sspProgrammatic;
    },
  },
  async created() {
    this.setLoad();
    await this.loadPlatform();
    this.setLoad(false);
    this.platform.client = this.activeClient;
  },
  methods: {
    ...mapActions({
      setLoad: SET_LOAD,
      setPropsLoadingToast: SET_LOADING_TOAST,
      setStateErrorToast: SET_STATE_ERROR_TOAST,
      setStateLoadingToast: SET_STATE_LOADING_TOAST,
      setStateSuccessToast: SET_STATE_SUCCESS_TOAST,
      createToast: CREATE_TOAST,
    }),
    async loadPlatform() {
      try {
        if (!this.platformId) return;
        this.setLoad();
        const { data } = await getPlatformById(this.platformId);
        this.platform = data;
        this.setLoad(false);
      } catch (error) {
        this.createToast(Toast.error(`Can't get platform`, error.message));
      }
    },
    onActiveClientChange() {
      this.$router.replace({ name: platformList.name });
    },
    metaTitleReplacement() {
      return { platform: this.platform.name } || {};
    },
    async save({ valid }) {
      try {
        if (!valid) return;
        this.loading = true;
        await updatePlatform(this.platform);
        this.createToast(Toast.success('Success!', 'The platform has been updated successfully'));
      } catch (error) {
        this.createToast(Toast.error(`We can't update platform`, error.message));
      }
      this.loading = false;
    },
    onEditPlatformFee() {
      this.showEditPlatformFeeModal = true;
    },
    async onSavePlatformFee() {
      await this.loadPlatform();
      this.showEditPlatformFeeModal = false;
    },
    async onReloadEmail(emailItem) {
      try {
        await reloadPlatformMail({ platformId: this.platform.id, emailId: emailItem.id });
        this.createToast(Toast.success('Success!', 'The email has been reloaded successfully'));
      } catch (e) {
        this.createToast(Toast.error('Failed to reload email', e.message));
      }
    },
  },
};
</script>

<style scoped>
::v-deep table tr td.flex.justify-center {
  max-width: none !important;
}
::v-deep table tr:first-child td {
  @apply bg-gray-200;
}
::v-deep table tr:last-child td {
  border-bottom: 0px;
}
</style>
