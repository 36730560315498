var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "w-full", attrs: { id: "platform-details" } },
    [
      _c("section-header", {
        attrs: { "is-loading": _vm.isLoading },
        scopedSlots: _vm._u(
          [
            !_vm.isLoading
              ? {
                  key: "title",
                  fn: function () {
                    return [_c("breadcrumb", { staticClass: "flex flex-1" })]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "div",
        { staticClass: "p-6 text-left bg-white rounded-lg shadow-md lg:p-12" },
        [
          _c(
            "sun-form",
            { on: { submit: _vm.save } },
            [
              _c("card-form", {
                staticClass: "platform-card",
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v("Basic Info")]
                    },
                    proxy: true,
                  },
                  {
                    key: "form",
                    fn: function () {
                      return [
                        _c("form-row", {
                          scopedSlots: _vm._u([
                            {
                              key: "left",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Name" } },
                                    [
                                      _c("asterix-input", {
                                        attrs: {
                                          id: "platform-name",
                                          label: "Name",
                                          maxlength: 50,
                                          minlength: 3,
                                          name: "platform-name",
                                          placeholder: "Platform name",
                                          required: "required",
                                        },
                                        model: {
                                          value: _vm.platform.name,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.platform, "name", $$v)
                                          },
                                          expression: "platform.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "right",
                              fn: function () {
                                return [
                                  _c(
                                    "sun-label-group",
                                    { attrs: { text: "Provider" } },
                                    [
                                      _c("asterix-input", {
                                        attrs: {
                                          disabled: "",
                                          label: "Provider",
                                          name: "provider",
                                          placeholder: "Platform Provider",
                                        },
                                        model: {
                                          value: _vm.platform.provider.name,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.platform.provider,
                                              "name",
                                              $$v
                                            )
                                          },
                                          expression: "platform.provider.name",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _c(
                          "form-row",
                          [
                            _c("save-button", {
                              attrs: {
                                loading: _vm.loading,
                                disabled: _vm.isLoading,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _c("card-form", {
            staticClass: "history-card mt-8",
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function () {
                  return [_vm._v(" Fee History")]
                },
                proxy: true,
              },
              {
                key: "form",
                fn: function () {
                  return [
                    _c("sun-data-table", {
                      attrs: {
                        headers: _vm.historyHeaders,
                        content: _vm.platform.platformFees,
                        hoverable: "",
                        loading: _vm.isLoading,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: `col.date`,
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "sun-data-table-cell",
                                  { class: { "font-bold": !item.endDate } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-row justify-center",
                                      },
                                      [
                                        _c("date-tooltip", {
                                          staticClass: "border-none",
                                          attrs: { date: item.startDate },
                                        }),
                                        item.endDate
                                          ? [
                                              _c(
                                                "span",
                                                { staticClass: "mx-3" },
                                                [_vm._v("-")]
                                              ),
                                              _c("date-tooltip", {
                                                staticClass: "border-none",
                                                attrs: { date: item.endDate },
                                              }),
                                            ]
                                          : [
                                              _c(
                                                "span",
                                                { staticClass: "mx-3" },
                                                [_vm._v("-")]
                                              ),
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.notEndDate)),
                                              ]),
                                            ],
                                      ],
                                      2
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: `col.openFee`,
                            fn: function ({ item, columnClass }) {
                              return [
                                _c(
                                  "sun-data-table-cell",
                                  {
                                    staticClass: "text-center",
                                    class: [
                                      { "font-bold": !item.endDate },
                                      columnClass,
                                    ],
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.openFee) + "%"),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: `col.dealFee`,
                            fn: function ({ item, columnClass }) {
                              return [
                                _c(
                                  "sun-data-table-cell",
                                  {
                                    staticClass: "text-center",
                                    class: [
                                      { "font-bold": !item.endDate },
                                      columnClass,
                                    ],
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(item.dealsFee) + "%"),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: `col.actions`,
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "sun-data-table-cell",
                                  [
                                    !item.endDate
                                      ? _c("table-action-menu", {
                                          attrs: {
                                            actions: _vm.historyActions,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onEditPlatformFee()
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "empty",
                            fn: function () {
                              return [
                                _c("asterix-no-data", {
                                  staticClass: "bg-white text-center",
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm.platform.emailIngestions.length
            ? _c("card-form", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function () {
                        return [_vm._v("Email ingestions")]
                      },
                      proxy: true,
                    },
                    {
                      key: "form",
                      fn: function () {
                        return [
                          _c("sun-data-table", {
                            attrs: {
                              headers: _vm.emailIngestionHeaders,
                              content: _vm.platform.emailIngestions,
                              hoverable: "",
                              loading: _vm.isLoading,
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `col.ingestionDate`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "sun-data-table-cell",
                                        {
                                          staticClass: "text-center font-bold",
                                        },
                                        [
                                          _c("date-tooltip", {
                                            staticClass: "border-none",
                                            attrs: { date: item.ingestionDate },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `col.status`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "sun-data-table-cell",
                                        [
                                          _c("status-pill", {
                                            attrs: { status: item.status },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `col.subject`,
                                  fn: function ({ item, columnClass }) {
                                    return [
                                      _c(
                                        "sun-data-table-cell",
                                        {
                                          staticClass: "font-bold",
                                          class: columnClass,
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.subject)),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `col.datesInEmail`,
                                  fn: function ({ item, columnClass }) {
                                    return [
                                      _c(
                                        "sun-data-table-cell",
                                        {
                                          staticClass: "font-bold",
                                          class: columnClass,
                                        },
                                        _vm._l(
                                          item.ingestionDates,
                                          function (date, index) {
                                            return _c(
                                              "span",
                                              {
                                                key: index,
                                                staticClass: "inline-block",
                                              },
                                              [
                                                _c("date-tooltip", {
                                                  staticClass:
                                                    "border-none mr-2",
                                                  attrs: { date: date },
                                                }),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: `col.actions`,
                                  fn: function ({ item }) {
                                    return [
                                      _c(
                                        "sun-data-table-cell",
                                        [
                                          _c("table-action-menu", {
                                            attrs: {
                                              actions: _vm.emailActions,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.onReloadEmail(item)
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "empty",
                                  fn: function () {
                                    return [
                                      _c("asterix-no-data", {
                                        staticClass: "bg-white text-center",
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2627778591
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showEditPlatformFeeModal
        ? _c("edit-fee-modal", {
            attrs: { fee: _vm.platform.platformFees[0] },
            on: {
              save: _vm.onSavePlatformFee,
              cancel: function ($event) {
                _vm.showEditPlatformFeeModal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }